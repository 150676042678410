import { Block } from '@stage-ui/core'
import BlockTypes from '@stage-ui/core/layout/Block/types'

export const Page = (props: BlockTypes.Props) => {
  return (
    <Block
      px={['xl', '*', 'm']}
      style={{
        margin: '0 auto',
        maxWidth: '74rem',
      }}
      {...props}
    />
  )
}
